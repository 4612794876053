import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, tap } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { appConfig } from "../config/app.config.dev";
import { AppConfigService } from "app/app-config.service";

@Injectable({
    providedIn: "root"
})
export class GeneralSettingsService {
    constructor(
        private http: HttpClient,
        private _appConfigService: AppConfigService
    ) { }

    private basePath = this._appConfigService.getConfigSubject().value;

    private settingsSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    settings$: Observable<any> = this.settingsSubject.asObservable();

    private appNameSubject = new BehaviorSubject<string>('');
    appName$ = this.appNameSubject.asObservable();

    private apiUrl = this.basePath + "admin/v1/general_settings";


    fetchSettings(): void {
        this.http.get(this.apiUrl).subscribe(
            settings => {
                this.settingsSubject.next(settings);
            },
            error => {
                console.error("Error loading settings", error);
            }
        );
    }

    updateSettings(settingsJson: string): Observable<void> {
        const formData = new FormData();
        formData.append("settingsJson", settingsJson);
        return this.http.put<void>(this.apiUrl, formData).pipe(tap(() => this.fetchSettings()));
    }

    updateBranding(settingsJson: string): Observable<void> {
        const formData = new FormData();
        formData.append("settingsJson", settingsJson);
        return this.http
            .put<void>(this.basePath + "admin/v1/general_settings/branding", formData)
            .pipe(tap(() => this.fetchSettings()));
    }

    resetSettings(): Observable<any> {
        return this.http
            .put<void>(this.basePath + "admin/v1/general_settings/branding/reset", {})
            .pipe(tap(() => this.fetchSettings()));
    }

    updateSealingCertificate(file: File, certPassword: string): Observable<void> {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("certPasswordRequest", certPassword);

        return this.http.put<void>(`${this.apiUrl}/sealing-cert`, formData, {
            headers: new HttpHeaders({
                enctype: "multipart/form-data"
            })
        });
    }

    updateEvidenceReportSigningCert(file: File, certPassword: string): Observable<void> {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("certPasswordRequest", certPassword);

        return this.http.put<void>(`${this.apiUrl}/evidence-report-signing-cert`, formData, {
            headers: new HttpHeaders({
                enctype: "multipart/form-data"
            })
        });
    }

    getDefaultConnectors(providerName: string): Observable<any> {
        return this.http.get<any>(this.basePath + "admin/v1/connectors/default/" + providerName);
    }

    getConnectorsList(providerName: string): Observable<any> {
        return this.http.get(this.basePath + "admin/v1/connectors/" + providerName);
    }
    generateRandomEncryptionKey(): Observable<string> {
        return this.http.get(`${this.basePath}admin/v1/encryption/secret`, { responseType: "text" });
    }
    generateRandomInitializationVector(): Observable<string> {
        return this.http.get(`${this.basePath}admin/v1/encryption/iv`, { responseType: "text" });
    }

    getAppName(configKey: string): Observable<any> {
        return this.http.get(`${this.basePath}admin/internal/getGeneralSettings?configKey=${configKey}`, { responseType: 'text' });
    }

    setAppName(appName: string) {
        this.appNameSubject.next(appName);
    }
}
