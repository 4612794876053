import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppConfigService {

  private configSubject = new BehaviorSubject<any>(null);

  setConfig(config: any) {
    this.configSubject.next(config);
  }

  getConfigSubject() {
    return this.configSubject;
  }

}
