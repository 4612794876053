import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { Observable, of, ReplaySubject, throwError } from "rxjs";
import { catchError, map, switchMap, tap } from "rxjs/operators";
import { appConfig } from "app/core/config/app.config.dev";
import { UserSettings } from "./setting.type";
import { AppConfigService } from "app/app-config.service";

@Injectable({
  providedIn: "root",
})
export class SettingService {
  private _userSettings: ReplaySubject<UserSettings> = new ReplaySubject<UserSettings>(1);

  constructor(
    private _httpClient: HttpClient,
    private _appConfigService: AppConfigService
  ) { }

  private basePath = this._appConfigService.getConfigSubject();

  /**
   * Setter & getter for user
   *
   * @param value
   */
  set userSetting(value: UserSettings) {
    // Store the value
    this._userSettings.next(value);
  }

  get userSetting$(): Observable<UserSettings> {
    return this._userSettings.asObservable();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Get the current logged in user data
   */
  get(): Observable<UserSettings> {
    //old api/common/user

    return this._httpClient.get<UserSettings>(this.basePath.value + "user/v1/settings/signatures").pipe(
      tap((signatureImageContent) => {
        this._userSettings.next(signatureImageContent);
      })
    );
  }

  updateUserName(userBioObject: { name: string; email: string }): Observable<any> {
    return this._httpClient.post(this.basePath.value + "v0/user/changeUserBio", userBioObject).pipe(
      switchMap((response: any) => {
        return of(response);
      })
    );
  }

  updatePassword(settingObject: { password: string; SettingKey: string; newPassword: string; mfa: Boolean }): Observable<any> {
    return this._httpClient.post(this.basePath.value + "v0/user/changePassword", settingObject).pipe(
      switchMap((response: any) => {
        return of(response);
      })
    );
  }

  generateQr(): Observable<any> {
    return this._httpClient.get(this.basePath.value + "v1/code/generate", { responseType: "arraybuffer" }).pipe(
      map((response: ArrayBuffer) => {
        const base64Image = this.arrayBufferToBase64(response);
        return "data:image/png;base64," + base64Image;
      })
    );
  }

  private arrayBufferToBase64(buffer: ArrayBuffer): string {
    const bytes = new Uint8Array(buffer);
    let binary = "";
    for (let i = 0; i < bytes.byteLength; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return btoa(binary);
  }
}
