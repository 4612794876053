import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { UserService } from 'app/core/user/user.service';
import { catchError, Observable, of, switchMap, throwError } from 'rxjs';
import { appConfig } from '../config/app.config.dev';
import { User } from '../user/user.types';
import { AdminService } from '../admin-service/admin.service';
import { PermissionsService } from '../permissionsService/permissions.service';
import { AppConfigService } from 'app/app-config.service';

@Injectable({ providedIn: 'root' })
export class AuthService {
    private _authenticated: boolean = false;
    private _httpClient = inject(HttpClient);
    private _userService = inject(UserService);
    private _adminService = inject(AdminService);
    private _permissionService = inject(PermissionsService);
    private _appConfigService = inject(AppConfigService);

    private basePath = this._appConfigService.getConfigSubject();

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for access token
     */
    set accessToken(token: string) {
        localStorage.setItem('accessToken', token);
    }

    get accessToken(): string {
        return localStorage.getItem('accessToken') ?? '';
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Forgot password
     *
     * @param username
     */
    forgotPassword(username: string): Observable<any> {
        return this._httpClient.post(this.basePath.value + 'auth/v1/password/forgot', { username });
    }

    /**
     * Reset password
     *
     * @param password
     */
    resetPassword(changeRequest: { username?: string, new_password: string; code: any }): Observable<any> {
        return this._httpClient.post(this.basePath.value + 'auth/v1/password/renew', changeRequest);
    }

    /**
     * Sign in
     *
     * @param credentials
     */
    signIn(credentials: { username: string; password: string }): Observable<any> {
        if (this._authenticated) {
            return throwError('Admin is already logged in.');
        }

        return this._httpClient.post(this.basePath.value + 'auth/v1/signin/admin', credentials).pipe(
            switchMap((response: any) => {
                if (response.accessToken != undefined) {

                    var tokenExpiry = response.expires_in;
                    sessionStorage.setItem("tokenExpiry", tokenExpiry);

                    // Store the access token in the local storage
                    this.accessToken = response.accessToken;

                    // Set the authenticated flag to true
                    this._authenticated = true;

                    this._adminService.getAdminDetails().subscribe((adminResponse) => {

                        let user: User = {
                            id: adminResponse.id,
                            name: adminResponse.name,
                            email: credentials.username,
                            username: adminResponse.username,
                        };

                        // Store the user on the user service
                        this._userService.user = user;
                        localStorage.setItem("directory", user.username);
                        localStorage.setItem("email", credentials.username);

                        this._permissionService.fetchPermissions();
                    });
                }

                // Return a new observable with the response
                return of(response);
            }),
        );
    }

    checkByUrlAndToken(url: any): Observable<any> {
        return this._httpClient.get<User>(this.basePath.value + "admin/v1/dashboard/profile").pipe(
            catchError(() =>
                // Return false
                of(false)
            ),
            switchMap((user: User) => {

                // this is necessary for refresh access token
                this._authenticated = true;

                //Check if user is guest
                if (url != "") {
                    return of(true);
                }
            })
        );
    }

    /**
     * Sign in using the access token
     */
    signInUsingToken(): Observable<any> {
        // Sign in using the token
        return this._httpClient
            .post('api/auth/sign-in-with-token', {
                accessToken: this.accessToken,
            })
            .pipe(
                catchError(() =>
                    // Return false
                    of(false),
                ),
                switchMap((response: any) => {
                    // Replace the access token with the new one if it's available on
                    // the response object.
                    //
                    // This is an added optional step for better security. Once you sign
                    // in using the token, you should generate a new one on the server
                    // side and attach it to the response object. Then the following
                    // piece of code can replace the token with the refreshed one.
                    if (response.accessToken) {
                        this.accessToken = response.accessToken;
                    }

                    // Set the authenticated flag to true
                    this._authenticated = true;

                    // Store the user on the user service
                    this._userService.user = response.user;

                    // Return true
                    return of(true);
                }),
            );
    }

    /**
     * Sign out
     */
    signOut(): Observable<any> {
        // Remove the access token from the local storage
        localStorage.removeItem('accessToken');

        // Set the authenticated flag to false
        this._authenticated = false;

        this._permissionService.removePermissions();

        // Return the observable
        return of(true);
    }

    /**
     * Sign up
     *
     * @param user
     */
    signUp(user: {
        name: string;
        username: string;
        emailAddress: string;
        password: string;
        // organization: string;
    }): Observable<any> {
        return this._httpClient.post(this.basePath.value + 'auth/v1/signup', user);
    }

    /**
     * 
     * @param code
     */

    verifyAccount(code: string): Observable<any> {
        return this._httpClient.get(this.basePath.value + "auth/v1/verifyAccount/" + code);
    }

    /**
     * Unlock session
     *
     * @param credentials
     */
    unlockSession(credentials: {
        email: string;
        password: string;
    }): Observable<any> {
        return this._httpClient.post('api/auth/unlock-session', credentials);
    }

    /**
     * Check the authentication status
     */
    check(redirectURL?: string): Observable<boolean> {
        // Check if the user is logged in
        if (this._authenticated) {
            return this.checkByUrlAndToken(redirectURL);
        }

        // Check the access token availability
        if (!this.accessToken) {
            return of(false);
        }

        // Check the access token expire date
        if (AuthUtils.isTokenExpired(this.accessToken)) {
            return of(false);
        }

        return this.checkByUrlAndToken(redirectURL);

        // If the access token exists, and it didn't expire, sign in using it
        // return this.signInUsingToken();
    }
}
